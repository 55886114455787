


















import { defineComponent, SetupContext } from '@vue/composition-api'

import AccordionItem from './_partials/AccordionItem.vue'

import { AccordionProps } from './Accordion.contracts'
import { accordionProps, useAccordion } from './Accordion.hooks'

export const Accordion = defineComponent({
  name: 'Accordion',
  components: { AccordionItem },
  props: accordionProps,
  emits: ['toggled'],

  setup (props: AccordionProps, ctx: SetupContext) {
    return useAccordion(props, ctx.emit)
  }
})

export default Accordion
